<template>
	<!-- Table Container Card -->
	<div>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0 mr-1">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 d-flex align-items-end">
							<b-button @click="getVaccineList()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->
				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getVaccineList()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add-vaccine variant="primary mr-1 ml-2"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="vaccineColumns && vaccineColumns.total > 0">
			<b-table
				:items="vaccineColumns.data"
				:fields="vaccineColumnFields"
				responsive
				id="vaccine-table"
				primary-key="id"
				empty-text="No matching records found"
				class="position-relative"
			>
				<!-- Column: Id -->
				<template #cell(index)="data">
					<div class="ml-1">
						{{ vaccineColumns.from + data.index }}
					</div>
				</template>
				<template #cell(unit)="data">
					<div class="ml-1">
						{{ data.item.unit.name }}
					</div>
				</template>
				<template #cell(route)="data">
					<div class="ml-1">
						{{ data.item.route.name }}
					</div>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon
							icon="EditIcon"
							size="16"
							v-b-modal.edit-vaccine
							@click="() => getVaccineById(data.item.id)"
						/>

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setVaccineId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-vaccine
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="vaccineColumns.current_page"
					:total-rows="vaccineColumns.total"
					:per-page="vaccineColumns.per_page"
					align="right"
					@change="getVaccineList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No vaccine added yet</h4>
		</div>
		<!-- vaccine modal -->
		<b-modal
			id="add-vaccine"
			title="Add Vaccine"
			ok-only
			ok-title="Submit"
			@ok="addVaccine"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="addVaccine">
					<b-form-group>
						<template v-slot:label> Enter Vaccine Name <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Vaccine Name">
							<b-form-input name="name" v-model="formData.name" placeholder="Enter Vaccine Name" maxlength="30" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
						<!-- :rules="{ regex: RegExp(/^[a-zA-Z\ ]*$/) }" -->
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Unit Name <span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name="Unit Name" rules="required">
								<v-select
									v-model="formData.unit_id"
									:options="unitList"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Route Name <span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name="Route Name" rules="required">
								<v-select
									v-model="formData.route_type_id"
									:options="routeList"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
		<!-- edit modal -->
		<b-modal
			id="edit-vaccine"
			title="Edit Vaccine"
			ok-only
			ok-title="Submit"
			@ok="updateVaccine"
			ref="my-modal"
			:no-close-on-backdrop="true"
		>
			<b-form ref="form">
				<b-form-group label="Enter Vaccine Name" label-for="Enter Vaccine Name ">
					<b-form-input
						name="name"
						v-model="formData.name"
						placeholder="Enter Vaccine Name"
						:rules="{ regex: RegExp(/^[A-Za-z0-9 ]+$/) }"
						maxlength="30"
					/>
				</b-form-group>
				<b-form-group label="Unit Name" label-for="Unit Name ">
					<div class="form-label-group">
						<v-select
							v-model="formData.unit_id"
							:options="unitList"
							label="name"
							clearable:false
							:reduce="(e) => e.id"
						/>
					</div>
				</b-form-group>
				<b-form-group label="Route Name" label-for="Route ">
					<div class="form-label-group">
						<v-select
							v-model="formData.route_type_id"
							:options="routeList"
							label="name"
							clearable:false
							:reduce="(e) => e.id"
						/>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>
		<!-- delete modal -->
		<DeleteModal id="modal-vaccine" :onClicked="deleteVaccine" />
	</div>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, regex } from "@validations";
export default {
	components: {
		vSelect,
		DeleteModal,
		vSelect,
	},
	data: () => ({
		dir: "ltr",
		currentPage: 1,
		required,
		regex,
		formData: {
			vaccine_name: null,
			unit_id: null,
			route_type_id: null,
		},
		search_filter: {
			input_field: "",
			to_date: "",
			from_date: "",
		},
		type: "vaccine",
		vaccineColumnFields: [
			{ key: "index", label: "S.no" },
			"name",
			{ key: "unit.name", label: "unit" },
			{ key: "route.name", label: "route" },
			{
				key: "updated_at",
				label: "updated at",
				sortable: true,
				formatter: (value) => {
					return moment(value).format("MMMM Do YYYY");
				},
			},
			"Actions",
		],
		vaccineColumns: [],
		unitList: [],
		routeList: [],
	}),
	created: function () {
		this.getVaccineList();
		this.getUnit();
		this.getRoute();
	},
	computed: {
		rows() {
			return this.vaccineColumnFields.length;
		},
	},
	methods: {
		moment() {
			return moment();
		},

		getVaccineList(pageNo = 1) {
			let url = `web/vaccines`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.vaccineColumns = response.data.vaccine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getUnit() {
			axiosIns
				.get(`web/units/${this.type}`)
				.then((response) => {
					this.unitList = response.data.unitList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getRoute() {
			axiosIns
				.get(`web/routeType/${this.type}`)
				.then((response) => {
					this.routeList = response.data.routeList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSelectedUnit(name) {
			this.formData.unit = name;
		},
		getSelectedRoute(name) {
			this.formData.route = name;
		},
		addVaccine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var formData = new FormData();
					let form_data = this.objectToFormData(this.formData, formData);
					axiosIns
						.post(`web/vaccines`, form_data)
						.then((res) => {
							res;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$refs["my-modal"].hide();
							this.getVaccineList();
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
						});
				}
			});
		},
		getVaccineById(id) {
			axiosIns
				.get(`web/vaccines/${id}`)
				.then((response) => {
					this.formData = response.data.vaccine;
					this.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateVaccine() {
			var formData = new FormData();
			formData.append("_method", "PUT");
			formData.append("name", this.formData.name);
			formData.append("unit_id", this.formData.unit_id);
			formData.append("route_type_id", this.formData.route_type_id);
			axiosIns
				.post(`web/vaccines/${this.id}`, formData)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Updated Successfully `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getVaccineList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.getVaccineLis();
				});
			// .catch((error) => {
			//   this.$refs.setErrors(error.response.data.error);
			//   this.getVaccineList();
			// });
		},
		setVaccineId(id) {
			this.id = id;
		},
		deleteVaccine() {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/vaccines/${this.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data Successfully Deleted `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getVaccineList();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.$refs["my-modal"].hide();
					this.getVaccineList();
				});
		},
		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-vaccine`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getVaccineList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getVaccineList();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getVaccineList();
		},
		resetModal() {
			this.formData.name = "";
			this.formData.unit_id = "";
			this.formData.route_type_id = "";
			this.formData.expiry_date = "";
		},
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
