<template>
  <div>
    <!-- Table Container Card -->

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Date picker -->
        <b-col cols="12" md="6">
          <b-row>
            <b-col md="4 p-0 mr-1">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="4 p-0">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2 d-flex align-items-end">
              <b-button @click="getBreedList()" variant="primary ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
          class="mt-2 d-flex justify-content-end align-items-center"
        >
          <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getBreedList()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button>
          <b-button v-b-modal.add-breed variant="primary mr-1 ml-2">
            Add
          </b-button>
          <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="breedColumns && breedColumns.total > 0">
      <b-table
        id="breedTable"
        :items="breedColumns.data"
        responsive
        :fields="fields"
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #cell(index)="data">
          <div class="ml-1">
            {{ breedColumns.from + data.index }}
          </div>
        </template>
        <template #cell(status)="data">
          <div v-if="data.value === 'Active'" class="activeClass">Active</div>
          <div v-else class="inactiveClass">Inactive</div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              size="16"
              v-b-modal.edit-breed
              @click="() => getBreedId(data.item.id)"
            />

            <b-tooltip
              title="Edit"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />

            <feather-icon
              @click="() => setBreedId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-breed
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>

      <div class="mt-2 mb-5">
        <b-pagination
          :value="breedColumns.current_page"
          :total-rows="breedColumns.total"
          :per-page="breedColumns.per_page"
          align="right"
          @change="getBreedList"
        >
        </b-pagination>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')"
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No breed added yet</h4>
    </div>

    <!-- add source modal -->
    <b-modal
      id="add-breed"
      title="Add Breed"
      ok-only
      ok-title="Submit"
      @ok="addBreed"
      ref="my-modal"
      @show="resetModal"
      @hidden="resetModal"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit="addBreed" method="post">
          <b-form-group>
            <template v-slot:label>
              Enter Breed Name <span class="text-danger">*</span>
            </template>
            <div class="form-label-group">
              <validation-provider
                #default="{ errors }"
                name="Breed Name"
                rules="required|alpha"
              >
                <b-form-input
                  v-model="formData.name"
                  name="breedName"
                  placeholder="Enter Breed Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Edit Breed modal -->
    <b-modal
      id="edit-breed"
      title="Edit Breed"
      ok-only
      ok-title="Submit"
      @ok="updateBreed"
      ref="my-modal"
    >
      <b-form method="post">
        <b-form-group label="Enter Breed Name">
          <div class="form-label-group">
            <b-form-input
              v-model="formData.name"
              name="breedName"
              placeholder="Enter Breed Name"
            />
          </div>
        </b-form-group>
        <b-form-group label="Status" label-for=" status">
          <div class="d-flex mt-1">
            <b-form-radio
              v-model="formData.status"
              name="status"
              value="Active"
              class="mr-1"
              >Active</b-form-radio
            >
            <b-form-radio
              v-model="formData.status"
              name="status"
              value="Inactive"
              >InActive</b-form-radio
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- delete modal -->
    <DeleteModal id="modal-breed" :onClicked="deleteBreed" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { url } from "@/@core/utils/validations/validations";
export default {
  components: {
    vSelect,
    DeleteModal,
  },
  data() {
    return {
      required,
      sortDesc: null,
      selected: null,
      search: null,
      formData: {
        name: null,
      },
      search_filter: {
        input_field: "",
        to_date: "",
        from_date: "",
      },
      fields: [
        { key: "index", label: "No" },
        "name",
        { key: "status", label: "status" },
        {
          key: "created_at",
          label: "created at",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("MMMM Do YYYY");
          },
        },
        "Actions",
      ],
      breedColumns: {},
      filterData: [],
      option: null,
      breedName: null,
      sourceList: null,
      page: null,
    };
  },
  created: function () {
    this.getBreedList();
  },

  computed: {
    rows() {
      return this.breedColumns.length;
    },
  },
  methods: {
    moment() {
      return moment();
    },
    resetModal() {
      this.formData.name = null;
    },
    getBreedList(pageNo = 1) {
      let url = `web/breeds`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }

      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.breedColumns = response.data.breedList;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },
    addBreed(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var formData = new FormData();
          let form_data = this.objectToFormData(this.formData, formData);
          axiosIns
            .post(`web/breeds`, form_data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Successfully Added `,
                },
              });
              this.$refs["my-modal"].hide();
              this.getBreedList();
            })
            .catch((error) => {
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
            });
        }
      });
    },
    getBreedId(id) {
      axiosIns
        .get(`web/breeds/${id}`)
        .then((response) => {
          this.formData = response.data.breed;
          this.id = id;
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },
    updateBreed() {
      var formData = new FormData();
      formData.append("_method", "PUT");
      let form_data = this.objectToFormData(this.formData, formData);
      axiosIns
        .post(`web/breeds/${this.id}`, form_data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `Data Updated Successfully `,
            },
          });
          this.$refs["my-modal"].hide();
          this.getBreedList();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },

    filterName(event) {
      let filterText = event.target.value;
      var newArray = this.breedColumns.filter(function (el) {
        return el.name.toLowerCase().includes(filterText.toLowerCase());
      });
      // (newArray);
      this.filterData = newArray;
    },
    setBreedId(id) {
      this.id = id;
    },
    deleteBreed(id) {
      var data = new FormData();
      data.append("_method", "DELETE");
      axiosIns
        .post(`web/breeds/${this.id}`, data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Data Successfully Deleted `,
            },
          });
          this.$refs["my-modal"].hide();
          this.getBreedList();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },

    excelDownload() {
      if (this.search_filter.from_date && this.search_filter.to_date) {
        this.search_filter.from_date = moment(
          this.search_filter.from_date
        ).format("YYYY-MM-DD");
        this.search_filter.to_date = moment(this.search_filter.to_date).format(
          "YYYY-MM-DD"
        );
      }
      let order_filter_data = this.prepareForm(this.search_filter);
      axiosIns
        .post(`web/export-breed`, order_filter_data)
        .then((response) => {
          var tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = response.data.file;
          tempLink.setAttribute("download", response.data.name);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          this.getBreedList();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getBreedList();
        });
    },
    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getBreedList();
    },
  },
};
</script>

<style lang="scss" scoped>
.activeClass {
  background-color: #28c76f;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  justify-content: center;
}
.inactiveClass {
  background-color: #ea5455;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 20px;
  justify-content: center;
  border-radius: 10px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
