<template>
	<div>
		<!-- Table Container Card -->

		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0 mr-1">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 d-flex align-items-end">
							<b-button @click="getFeedList()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->
				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getFeedList()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add-feed variant="primary mr-1 ml-2"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="feedData && feedData.total > 0">
			<b-table
				:items="feedData.data"
				id="feedTable"
				responsive
				:fields="fields"
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ feedData.from + data.index }}
					</div>
				</template>
				<template #cell(production_type)="data">
					<span v-if="data.item.production_type">{{ data.item.production_type.name }}</span>
					<span v-else>NA</span>
				</template>
				<!-- <template #cell(breed)="data">
					<span v-if="data.item.breed">{{ data.item.breed.name }}</span>
					<span v-else>NA</span>
				</template>
				<template #cell(breed_stage)="data">
					<span v-if="data.item.breed_stage">{{ data.item.breed_stage.name }}</span>
					<span v-else>NA</span>
				</template> -->
				<template #cell(status)="data">
					<div v-if="data.value === 'Active'" class="activeClass">Active</div>
					<div v-else class="inactiveClass">Inactive</div>
				</template>

				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon icon="EditIcon" size="16" v-b-modal.edit-feed @click="() => getFeedById(data.item.id)" />

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setFeedId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-feed
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="feedData.current_page"
					:total-rows="feedData.total"
					:per-page="feedData.per_page"
					align="right"
					@change="getFeedList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No feed added yet</h4>
		</div>
		<!-- Feed modal -->
		<b-modal
			id="add-feed"
			title="Add Feed"
			ok-only
			@ok="addFeed"
			ok-title="Submit"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<validation-observer ref="simpleRules">
				<b-form @submit="addFeed" method="post">
					<b-form-group>
						<template v-slot:label> Feed Name <span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="Feed Name"
							:rules="{
								required: true,
							}"
						>
							<b-form-input v-model="formData.name" name="name" placeholder="Feed Name" maxlength="30" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Production Type<span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name=" Production Type" rules="required">
								<v-select
									v-model="formData.production_type_id"
									:options="productionTypes"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
									@input="getBreedType"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
					<!-- <b-form-group>
						<template v-slot:label> Breed Type<span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name=" Breed Type" rules="required">
								<v-select
									v-model="formData.breed_id"
									:options="breeds"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Breed Stages<span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name=" Breed Stage" rules="required">
								<v-select
									v-model="formData.breed_stage_id"
									:options="stages"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group> -->
					<b-form-group>
						<template v-slot:label> Feed Type<span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name=" Feed Type" rules="required">
								<v-select
									v-model="formData.feed_type_id"
									:options="feed_type"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Unit Name <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Unit Name" rules="required">
							<v-select
								v-model="formData.unit"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="unitList"
								label="name"
								:reduce="(e) => e.id"
								clearable:false
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label label-cols-sm="3"> Crude Protein% </template>
						<ValidationProvider
							#default="{ errors }"
							name="Crude Protein %"
							:rules="{
								regex: /^\d+(\.\d{0,2})?$/,
							}"
						>
							<b-form-input
								v-model="formData.crude_protein"
								name="crude protein"
								placeholder="Crude Protein %"
								maxlength="10"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Energy Kcal </template>
						<ValidationProvider
							#default="{ errors }"
							name="Energy Kcal"
							:rules="{
								regex: /^\d+(\.\d{0,2})?$/,
							}"
						>
							<b-form-input
								v-model="formData.energy_kcal"
								name="energy Kcal"
								placeholder="Energy Kcal"
								maxlength="10"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>

		<!-- Edit  modal -->
		<b-modal
			id="edit-feed"
			title="Edit Feed"
			ok-only
			@ok="updateFeed"
			ok-title="Submit"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
		>
			<b-form method="post">
				<b-form-group label="Feed Name" label-for="Feed Name ">
					<b-form-input
						v-model="formData.name"
						name="name"
						placeholder="Feed Name"
						maxlength="30"
						:rules="{
							regex: RegExp(/^[a-zA-Z\ ]*$/),
						}"
					/>
				</b-form-group>
				<b-form-group label="Unit Name" label-for="Unit Name ">
					<div class="form-label-group">
						<v-select v-model="formData.unit" :options="unitList" label="name" clearable:false />
					</div>
				</b-form-group>
				<b-form-group label="Feed Type" label-for="Feed Type">
					<div class="form-label-group">
						<v-select v-model="formData.feed_type" :options="feedType" label="name" clearable:false />
					</div>
				</b-form-group>
				<b-form-group label="Status" label-for=" status">
					<div class="d-flex mt-1">
						<b-form-radio v-model="formData.status" name="status" value="Active" class="mr-1">Active</b-form-radio>
						<b-form-radio v-model="formData.status" name="status" value="Inactive">InActive</b-form-radio>
					</div>
				</b-form-group>
				<b-form-group label="Crud protein%" label-for="Crud protein%">
					<b-form-input
						v-model="formData.crude_protein"
						name="name"
						placeholder="Crud protein%"
						maxlength="10"
						rules="double"
					/>
				</b-form-group>
				<b-form-group label="Energy Kcal" label-for="Energy Kcal">
					<b-form-input
						v-model="formData.energy_kcal"
						name="name"
						placeholder="Energy Kcal"
						maxlength="10"
						rules="required"
					/>
				</b-form-group>
			</b-form>
		</b-modal>

		<!-- delete modal -->
		<DeleteModal id="modal-feed" :onClicked="deleteFeed" />
	</div>
</template>

<script>
import { required, alpha, regex } from "@validations";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";

export default {
	components: {
		vSelect,
		DeleteModal,
	},
	data() {
		return {
			alpha,
			regex,
			required,
			value: null,
			name: null,
			unit: null,
			page: null,
			formData: {
				name: null,
				unit: null,
				feed_type: null,
				selected: null,
				energy_kcal: null,
				crude_protein: null,
			},
			type: "feed",
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			fields: [
				{ key: "index", label: "S.no" },
				"name",
				"production_type",
				// "breed",
				// "breed_stage",
				"crude_protein",
				"energy_kcal",
				{ key: "feed_type.name", label: "feed Type" },
				{ key: "unit.name", label: "unit" },
				{ key: "status", label: "status" },
				"Actions",
			],
			// feedName: null,
			feedData: [],
			unitList: [],
			feedType: [],
			productionTypes: [],
			breeds: [],
			stages: [],
			feed_type: [],
		};
	},
	created: function () {
		this.getFeedList();
		this.getUnit();
		// this.getFeedType();
		this.getProductionTypes();
	},
	methods: {
		moment() {
			return moment();
		},

		getFeedList(pageNo = 1) {
			let url = `web/feeds`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}
			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.feedData = response.data.feed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getUnit() {
			axiosIns
				.get(`web/units/${this.type}`)
				.then((response) => {
					this.unitList = response.data.unitList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
					this.feed_type = response.data.feed_type;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBreedType(productionType) {
			axiosIns
				.get(`web/breeds/${productionType}`)
				.then((response) => {
					this.breeds = response.data.breeds;
					this.stages = response.data.stages;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
		getFeedType() {
			axiosIns
				.get(`web/feeds`)
				.then((response) => {
					this.feedList = response.data.feed_list.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addFeed(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var formData = new FormData();
					let form_data = this.objectToFormData(this.formData, formData);
					axiosIns
						.post(`web/feeds`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data successfully Added `,
								},
							});
							this.$refs["my-modal"].hide();
							this.getFeedList();
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
							this.getFeedList();
						});
				}
			});
		},
		getFeedById(id) {
			axiosIns
				.get(`web/feeds/${id}`)
				.then((response) => {
					this.formData = response.data.feed;
					this.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateFeed() {
			var formData = new FormData();
			formData.append("_method", "PUT");
			let form_data = this.objectToFormData(this.formData, formData);
			axiosIns
				.post(`web/feeds/${this.id}`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Updated Successfully `,
						},
					});
					this.getFeedList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.getFeedList();
				});
			// .catch((error) => {
			//   this.$refs.setErrors(error.response.data.error);
			//   this.getFeedList();
			// });
		},
		setFeedId(id) {
			this.id = id;
		},
		deleteFeed() {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/feeds/${this.id}`, data)
				.then((response) => {
					response;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data successfully Deleted `,
						},
					});
					this.getFeedList();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.getFeedList();
				});
		},
		getSelectedUnit(name) {
			this.formData.unit = name;
		},
		getSelectedFeed(name) {
			this.formData.feedType = name;
		},
		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-feeds`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getFeedList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFeedList();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getFeedList();
		},
		resetModal() {
			this.formData.name = "";
			this.formData.unit = "";
			this.formData.feet_type = "";
			this.formData.crude_protein = "";
			this.formData.energy_kcal = "";
		},
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
