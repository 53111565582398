<template>
	<div>
		<!-- Table Container Card -->

		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0 mr-1">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 d-flex align-items-end">
							<b-button @click="getMedicineList()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->
				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getMedicineList()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add-medicine variant="primary mr-1 ml-2"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="medicineColumns && medicineColumns.total > 0">
			<b-table
				:items="medicineColumns.data"
				id="medicineTable"
				responsive
				:fields="fields"
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ medicineColumns.from + data.index }}
					</div>
				</template>
				<template #cell(unit)="data">
					<div class="ml-1">
						{{ data.item.unit ? data.item.unit.name : "NA" }}
					</div>
				</template>
				<template #cell(route)="data">
					<div class="ml-1">
						{{ data.item.route ? data.item.route.name : "NA" }}
					</div>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon
							icon="EditIcon"
							size="16"
							v-b-modal.edit-medicine
							@click="() => getMedicineById(data.item.id)"
						/>

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setMedicineId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-medicine
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mt-2 mb-5">
				<b-pagination
					:value="medicineColumns.current_page"
					:total-rows="medicineColumns.total"
					:per-page="medicineColumns.per_page"
					align="right"
					@change="getMedicineList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No medicine added yet</h4>
		</div>
		<!-- Medicine modal -->
		<b-modal
			id="add-medicine"
			title="Add Medicine"
			ok-only
			@ok="addMedicine"
			ok-title="Submit"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<validation-observer ref="simpleRules">
				<b-form @submit="addMedicine" method="post">
					<b-form-group>
						<template v-slot:label> Enter Medicine Name <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Medicine Name">
							<b-form-input
								v-model="formData.name"
								name="medicineName"
								placeholder="Enter Medicine Name"
								maxlength="30"
							/>
							<!-- :rules="{ regex: RegExp(/^[a-zA-Z\ ]*$/) }" -->
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Unit Name <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Unit Name" rules="required">
							<v-select
								v-model="formData.unit_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="unitList"
								label="name"
								:reduce="(e) => e.id"
								clearable:false
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Route Name <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Route Name" rules="required">
							<v-select
								v-model="formData.route_type_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="routeList"
								label="name"
								:reduce="(e) => e.id"
								clearable:false
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
		<!-- Edit  modal -->
		<b-modal
			id="edit-medicine"
			title="Edit Medicine"
			ok-only
			@ok="updateMedicine"
			ok-title="Submit"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<b-form method="post">
				<b-form-group label="Enter Medicine Name" label-for="Enter Medicine Name ">
					<b-form-input
						v-model="formData.name"
						name="medicineName"
						placeholder="Enter Vaccine Name"
						:rules="{ regex: RegExp(/^[a-zA-Z\ ]*$/) }"
						maxlength="30"
					/>
				</b-form-group>
				<b-form-group label="Unit Name" label-for="Unit Name ">
					<div class="form-label-group">
						<v-select
							:options="unitList"
							v-model="formData.unit_id"
							label="name"
							:reduce="(e) => e.id"
							clearable:false
						/>
					</div>
				</b-form-group>
				<b-form-group label="Route Name" label-for="Route ">
					<div class="form-label-group">
						<v-select
							v-model="formData.route_type_id"
							:options="routeList"
							:reduce="(e) => e.id"
							label="name"
							clearable:false
						/>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>

		<!-- delete modal -->
		<DeleteModal id="modal-medicine" :onClicked="deleteMedicine" />
	</div>
</template>

<script>
import { required, regex } from "@validations";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
	components: {
		vSelect,
		DeleteModal,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
			regex,
			selectedUnit: null,
			selectedRoute: null,
			unitList: null,
			routeList: null,
			company_name: null,
			value: null,
			page: null,
			searchQuery: null,
			formData: {
				name: null,
				unit: null,
				route: null,
			},
			type: "medicine",
			search_filter: {
				input_field: null,
				to_date: null,
				from_date: null,
			},
			fields: [
				{ key: "index", label: "S.no" },
				"name",
				{ key: "unit", label: "unit" },
				{ key: "route", label: "route" },
				"Actions",
			],
			medicineName: null,
			medicineColumns: {},
		};
	},
	created: function () {
		this.getUnit();
		this.getRoute();
	},
	mounted: function () {
		this.getMedicineList();
	},

	methods: {
		moment() {
			return moment();
		},
		resetModal() {
			this.formData.name = "";
			this.formData.unit = "";
			this.formData.route = "";
			this.formData.expiry_date = "";
			this.formData.unit_id = "";
			this.formData.route_type_id = "";
		},
		getMedicineList(pageNo = 1) {
			let url = `web/medicines`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}
			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.medicineColumns = response.data.medicine_list;
				})
				.catch((error) => {
					console.log(err.response.data.errors);
					this.$refs.setErrors(error.response.data.errors);
				});
		},
		getUnit() {
			axiosIns
				.get(`web/units/${this.type}`)
				.then((response) => {
					this.unitList = response.data.unitList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getRoute() {
			axiosIns
				.get(`web/routeType/${this.type}`)
				.then((response) => {
					this.routeList = response.data.routeList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addMedicine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var formData = new FormData();
					let form_data = this.objectToFormData(this.formData, formData);
					axiosIns
						.post(`web/medicines`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data successfully Added `,
								},
							});
							this.$refs["my-modal"].hide();
							this.getMedicineList();
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
							this.getMedicineList();
						});
				}
			});
		},
		getMedicineById(id) {
			axiosIns
				.get(`web/medicines/${id}`)
				.then((response) => {
					this.formData = response.data.medicine;
					this.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateMedicine() {
			var form_data = new FormData();
			form_data.append("_method", "PUT");
			form_data.append("name", this.formData.name);
			form_data.append("unit_id", this.formData.unit_id);
			form_data.append("route_type_id", this.formData.route_type_id);
			axiosIns
				.post(`web/medicines/${this.id}`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Updated Successfully `,
						},
					});
					this.getMedicineList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.getMedicineList();
				});
			// .catch((error) => {
			//   this.$refs.setErrors(error.response.data.errors);
			//   this.getMedicineList();
			// });
		},
		setMedicineId(id) {
			this.id = id;
		},
		deleteMedicine() {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/medicines/${this.id}`, data)
				.then((response) => {
					response;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data successfully Deleted `,
						},
					});
					this.getMedicineList();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.getMedicineList();
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-medicine`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getMedicineList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getMedicineList();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getMedicineList();
		},
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
