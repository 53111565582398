<template>
	<div>
		<!-- Table Container Card -->

		<div class="m-2">
			<!-- Table Top -->
			<b-row responsive>
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0 mr-1">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								local="en"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 d-flex align-items-end">
							<b-button @click="getSourceList()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->
				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getSourceList()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add-source variant="primary mr-1 ml-2"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="sourceColumns && sourceColumns.total > 0">
			<b-table
				id="sourceTable"
				:items="sourceColumns.data"
				responsive
				:fields="fields"
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ sourceColumns.from + data.index }}
					</div>
				</template>
				<template #cell(status)="data">
					<div v-if="data.value === 'Active'" class="activeClass">Active</div>
					<div v-else class="inactiveClass">Inactive</div>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon icon="EditIcon" size="16" v-b-modal.edit-source @click="() => getSourceById(data.item.id)" />

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setSourceId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-source
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>

			<div class="mt-2 mb-5">
				<b-pagination
					:value="sourceColumns.current_page"
					:total-rows="sourceColumns.total"
					:per-page="sourceColumns.per_page"
					align="right"
					@change="getSourceList"
				>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No source added yet</h4>
		</div>

		<!-- add source modal -->
		<b-modal
			id="add-source"
			title="Add Source"
			ok-only
			ok-title="Submit"
			@ok="addSource"
			ref="my-modal"
			@show="resetModal"
			@hidden="resetModal"
		>
			<ValidationObserver ref="simpleRules">
				<b-form @submit="addSource" method="post">
					<b-form-group label="  ">
						<template v-slot:label> Enter Source Name <span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<ValidationProvider #default="{ errors }" name="Source Name">
								<b-form-input
									v-model="formData.name"
									name="sourceName"
									placeholder="Enter Source Name"
									maxlength="99"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</div>
					</b-form-group>
					<b-form-group label="">
						<template v-slot:label> Enter Source Address <span class="text-danger">*</span> </template>
						<ValidationProvider #default="{ errors }" name="Source Address" rules="required">
							<b-form-textarea
								v-model="formData.address"
								placeholder="Enter Source Address"
								name="address"
								rows="3"
								maxlength="100"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-form-group>
				</b-form>
			</ValidationObserver>
		</b-modal>
		<!-- edit source modal -->
		<b-modal id="edit-source" title="Edit Source" ok-only ok-title="Submit" @ok="updateSource" ref="my-modal">
			<ValidationObserver ref="simpleRules">
				<b-form @submit="addSource" method="post">
					<b-form-group label="Enter Source Name">
						<div class="form-label-group">
							<b-form-input
								v-model="formData.name"
								name="sourceName"
								placeholder="Enter Source Name"
								:rules="{ regex: RegExp(/^[a-zA-Z\ ]*$/) }"
								maxlength="20"
							/>
						</div>
					</b-form-group>
					<b-form-group label="Status" label-for=" status">
						<div class="d-flex mt-1">
							<b-form-radio v-model="formData.status" name="status" value="Active" class="mr-1">Active</b-form-radio>
							<b-form-radio v-model="formData.status" name="status" value="Inactive">InActive</b-form-radio>
						</div>
					</b-form-group>
					<b-form-group label="Enter Source Address">
						<b-form-textarea
							v-model="formData.address"
							placeholder="Enter Source Address"
							name="address"
							maxlength="100"
							rows="3"
						/>
					</b-form-group>
				</b-form>
			</ValidationObserver>
		</b-modal>
		<!-- delete modal -->
		<DeleteModal id="modal-source" :onClicked="deleteSource" />
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
	components: {
		vSelect,
		DeleteModal,
		ValidationProvider,
	},
	data() {
		return {
			required,
			email,
			selected: null,
			search: null,
			sortDesc: null,
			page: null,
			fields: [
				{
					key: "index",
					label: "No",
				},
				"name",
				{ key: "status", label: "status" },
				{
					key: "created_at",
					label: "created at",
					sortable: true,
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				"Actions",
			],
			sourceColumns: {},
			filterData: [],
			option: null,
			formData: {
				name: null,
				address: null,
			},
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			sourceList: null,
		};
	},
	created: function () {
		this.getSourceList();
	},

	methods: {
		moment() {
			return moment();
		},
		resetModal() {
			this.formData.name = "";
			this.formData.address = "";
		},
		getSourceList(pageNo = 1) {
			let url = `web/source`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.sourceColumns = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSourceById(id) {
			axiosIns
				.get(`web/source/${id}`)
				.then((response) => {
					this.formData = response.data.source;
					this.formData.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateSource() {
			var formData = new FormData();
			formData.append("_method", "PUT");
			let form_data = this.objectToFormData(this.formData, formData);
			axiosIns
				.post(`web/source/${this.formData.id}`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Successfully Updated `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.getSourceList();
				});
		},
		addSource(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var formData = new FormData();
					let form_data = this.objectToFormData(this.formData, formData);
					axiosIns
						.post(`web/source`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});

							this.$refs["my-modal"].hide();
							//   this.$refs.my-modal.reset();
							this.getSourceList();
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
							this.getSourceList();
						});
				}
			});
		},

		filterName(event) {
			let filterText = event.target.value;
			var newArray = this.sourceColumns.filter(function (el) {
				return el.name.toLowerCase().includes(filterText.toLowerCase());
			});
			// (newArray);
			this.filterData = newArray;
		},

		setSourceId(id) {
			this.formData.id = id;
		},

		deleteSource(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/source/${this.formData.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `data Successfully Deleted `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getSourceList();
				})
				.catch((error) => {
					const data = error.response.data.message;

					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.$refs["my-modal"].hide();
					this.getSourceList();
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-source`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getSourceList();
		},
	},
};
</script>

<style lang="scss">
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px !important;
	height: 20px !important;
	border-radius: 10px;
	justify-content: center;
}
.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}
.grid-pagination button {
	font-size: 12px;
	padding: 4px 0px;
	background: transparent;
	color: #6b6b6b;
	border: 0;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
}

.grid-pagination.custom-pagination button {
	font-size: 14px;
	padding: 4px 0px;
	background: #1985ac;
	color: #ffffff;
	border: 0;
	/* border-right: 1px solid #fff;
	border-left: 1px solid #fff; */
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
